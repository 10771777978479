import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { useAccountsStore } from '@/stores/accounts';
import { onError } from '@apollo/client/link/error';

// Custom fetch function
const customFetch = (uri, options = {}) => {
	options = {
		...options,
		credentials: 'include', // Ensure cookies are sent
		headers: {
			...options.headers,
		},
	};
	return fetch(uri, options);
};
// Create the HTTP link
const httpLink = createHttpLink({
	uri: window.location.origin + '/api/v1/reports/charts/graphql',
	fetch: customFetch, // Use the custom fetch here
	credentials: 'include', // Ensure cookies are sent with the request
});

// Create the error link to log errors
const errorLink = onError(async ({ networkError }) => {
	const isUnauthorizedError =
		networkError &&
		networkError.response &&
		(networkError.response.status === 401 ||
			networkError.response.statusText === 'Unauthorized' ||
			networkError.statusCode === '401');

	if (isUnauthorizedError || networkError.includes('status code 401')) {
		// Refresh the token
		let token = await refreshToken();

		// reload the page if get token
		if (token) {
			window.location.reload();
		}
	}
	// console.error(`Network Error: ${networkError}`);
	// console.log('networkError', networkError);
});

const currentTime = new Date().getTime();
const lastRefreshTimestamp =
	parseInt(localStorage.getItem('authTokenTimestamp'), 10) || 0;

// Create the context link to add the token to the headers
const authLink = setContext(async (_, { headers }) => {
	let token = localStorage.getItem('authToken');

	// send after 30 minutes send request
	if (!token || currentTime - lastRefreshTimestamp > 1800000) {
		await refreshToken();
	}

	return {
		headers: {
			...headers,
			'Authorization-Lexmodo-Admin-Chart': token || '',
		},
	};
});

// Function to refresh the token// Function to refresh the token
async function refreshToken() {
	const accountsStore = useAccountsStore();
	try {
		const { chartSettings } = await accountsStore.GenerateChartToken();
		const token = chartSettings.token;
		const currentTime = new Date().getTime();

		// Store the new token and timestamp
		localStorage.setItem('authToken', token);
		localStorage.setItem('authTokenTimestamp', currentTime);

		return token;
	} catch (error) {
		console.error('Error refreshing token gRPC:', error);
	}
}

// Periodic token refresh every 30 minutes
setInterval(async () => {
	await refreshToken();
}, 30 * 60 * 1000); // 30 minutes

// Cache implementation
const cache = new InMemoryCache();

// Create the Apollo Client with the authentication link
export const apolloClient = new ApolloClient({
	link: errorLink.concat(authLink).concat(httpLink),
	credentials: 'include',
	cache,
	headers: {
		cookie: document.cookie, // Ensures the request sends stored cookies
	},
});
