<template>
	<Loading
		:active="true"
		:is-full-page="full"
		color="#007bff"
		loader="dots"
		:height="50"
		:width="50"
	></Loading>
</template>

<script>
	import Loading from 'vue-loading-overlay';
	import 'vue-loading-overlay/dist/css/index.css';

	export default {
		components: { Loading },
		props: {
			full: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>

<style></style>
