<template>
	<div class="form-check">
		<input
			v-bind="dynamicProps"
			type="checkbox"
			style="border: 2px solid #ced4da"
			class="form-check-input"
			v-model="checkboxModel"
			:id="id"
			:required="required"
			:disabled="disabled"
			:readonly="readonly"
			:checked="internalChecked"
			@change="emitChange($event)"
			ref="checkboxReference"
		/>
		<label class="form-check-label" :for="id"><slot></slot></label>
	</div>
</template>

<script>
	export default {
		props: {
			name: {
				type: String,
				default: undefined,
			},
			modelValue: {
				type: [Object, String, Number, Boolean, Array],
				default: undefined,
			},
			val: {
				type: [Object, String, Number, Boolean, Array],
				default: undefined,
			},
			trueValue: {
				type: [String, Number, Boolean],
				default: undefined,
			},
			falseValue: {
				type: [String, Number, Boolean],
				default: undefined,
			},
			checked: {
				type: Boolean,
				default: undefined,
			},
			required: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			readonly: {
				type: Boolean,
				default: false,
			},
		},

		emits: ['change', 'update:modelValue'],

		data() {
			return {
				id: 'checkbox-id-' + this._.uid,
			};
		},

		created() {
			if (!this.$_.isUndefined(this.checked))
				this.checkboxModel = this.checked;
		},

		computed: {
			dynamicProps() {
				let dynamicPropsObj = {};
				if (!this.$_.isUndefined(this.name)) {
					dynamicPropsObj.name = this.name;
				}

				if (!this.$_.isUndefined(this.val)) {
					dynamicPropsObj.value = this.val;
				}
				return dynamicPropsObj;
			},
			internalChecked() {
				return (
					this.checked ||
					(!this.$_.isUndefined(this.val) &&
						this.indexOnSelectedItemsArray != -1) ||
					(!this.$_.isUndefined(this.modelValue) &&
						!this.$_.isUndefined(this.trueValue) &&
						this.modelValue == this.trueValue)
				);
			},
			checkboxModel: {
				get() {
					//if array type checkbox
					if (
						!this.$_.isUndefined(this.val) &&
						this.indexOnSelectedItemsArray != -1
					) {
						return true;
					}
					// not array type checkbox
					return this.checked;

					// if (
					// 	!this.$_.isUndefined(this.val) &&
					// 	Array.isArray(this.modelValue)
					// )
					// 	return this.indexOnSelectedItemsArray !== -1;

					// // has checked attribute
					// if (!this.$_.isUndefined(this.checked)) return this.checked;

					// // has val, v-model/value and v-model/value == val
					// if (
					// 	!this.$_.isUndefined(this.modelValue) &&
					// 	!Array.isArray(this.modelValue) &&
					// 	!this.$_.isUndefined(this.val)
					// )
					// 	return this.val == this.modelValue;

					// // has value/v-model and true-value
					// if (
					// 	!this.$_.isUndefined(this.modelValue) &&
					// 	!this.$_.isUndefined(this.trueValue)
					// )
					// 	return this.modelValue == this.trueValue;

					// return this.modelValue === this.falseValue
					// 	? false
					// 	: this.modelValue;
				},
				set(checkedValue) {
					if (
						this.$_.isUndefined(this.val) ||
						!Array.isArray(this.modelValue)
					) {
						//not array type checkbox
						if (
							checkedValue &&
							!this.$_.isUndefined(this.trueValue)
						)
							this.$emit('update:modelValue', this.trueValue);
						else if (
							!checkedValue &&
							!this.$_.isUndefined(this.falseValue)
						)
							this.$emit('update:modelValue', this.falseValue);
						else this.$emit('update:modelValue', checkedValue);
					} else {
						// array type checkbox
						let index = this.indexOnSelectedItemsArray;
						if (index == -1 && checkedValue) {
							this.$emit('update:modelValue', [
								...this.modelValue,
								this.val,
							]);
						} else if (index != -1 && !checkedValue) {
							// create a copy to avoid mutation on the prop
							let copy = [...this.modelValue];
							copy.splice(index, 1);
							this.$emit('update:modelValue', copy);
						}
					}
				},
			},
			indexOnSelectedItemsArray() {
				if (this.$_.isUndefined(this.modelValue)) return -1;
				return this.modelValue.findIndex((e) => e == this.val);
			},
		},

		methods: {
			emitChange(e) {
				this.$emit('change', e);
			},
		},
	};
</script>

<style></style>
